.react-datepicker-wrapper input {
  width: 100%;
  border-width: 0 0 2px 0;
  border-color: #CACACA;
  background: transparent;
  color: #5d5e60;
  font-size: 1rem;
  font-family: 'D-din';
  font-weight: 400;
  outline: none!important;
  padding: 4px 0 10px;
}
.react-datepicker-wrapper input:focus,
.react-datepicker-wrapper input:focus-visible {
  outline: none!important;
  border-color: #f1b80e!important;
  box-shadow: none!important;
}
.react-datepicker-wrapper input::placeholder {
  color: #6c757d;
}
.swal2-container {
  z-index: 10000!important;
}

.link-ct {
  color: #5C5D60; 
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  background-color:white;
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}